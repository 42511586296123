<template>
  <div>
		<loading-overlay :active="loading" :is-full-page="true" loader="bars"/>
    <CRow>
      <CCol sm="12" class="d-flex justify-content-end py-2">
      	<CButton
          color="add"
          @click="(isEdit=false, ModalActive=true)"
        >
          <CIcon name="cil-playlist-add"/>&nbsp;{{$t('label.AssociateEventsPerYard')}}
        </CButton>
      </CCol>
			<CCol sm="12" lg="4">
				<CSelect
          :options="ComputedYardOptions"
          v-model="YardId"
          :label="$t('label.yard')"
          :horizontal="{label:'col-sm-12 col-lg-auto', input:'col-sm-12 col-lg-8'}"
          addLabelClasses="text-right"
          @change="ConsultServices()"
        />
			</CCol>
      <CCol sm="12">
        <dataTableExtended
          class="align-center-row-datatable"
          :items="computedList"
          :fields="fields"
          column-filter
          :items-per-page-select="tableText.itemsPerPageText"
          :items-per-page="5"
          pagination
          sorter
          :tableFilter="tableText.tableFilterText"
          :noItemsView="tableText.noItemsViewText"
        >
          <template #Status="{ item }">
            <td class="text-center">
              <CBadge :color="getBadge(item.Status)">
                {{ $t('label.'+item.Status) }}
              </CBadge>
            </td>
          </template>
          <template #options="{ item }">
            <td class="text-center">
              <CButton
                size="sm"
                color="edit"
                @click="EditEventPerYard(item)"
                v-c-tooltip="{
                  content: `${$t('label.edit')} ${$t('label.AssociateEventsPerYard')}`,
                  placement: 'top-end'
                }"
              >
                <CIcon name="pencil" />
              </CButton>
            </td>
          </template>
        </dataTableExtended>
      </CCol>
    </CRow>
    <ModalEventPerYard
			:modal="ModalActive"
			:isEdit="isEdit"
			:EventPerYardItem="EventPerYardItem"
			@Close-Modal="(ModalActive=false, EventPerYardItem={})"
			@List-Update="(YardId='',ConsultServices(true));"
		/>
  </div>
</template>
<script>
import { DateFormater } from '@/_helpers/funciones';
import { mapState } from 'vuex';
import GeneralMixin from '@/_mixins/general';
import ModalEventPerYard from './modal-event-per-yard';

function data() {
  return {
		YardId: '',
		YardOptions: [],
    EventPerYardList: [],
		EventPerYardItem: {},
		ModalActive: false,
		isEdit: false,
    loading: false,
  };
}

//methods
function EditEventPerYard(item) {
	this.isEdit = true;
	this.EventPerYardItem = item;
	this.ModalActive = true;
}

function ConsultServices(Yard) {
  this.loading = true;
  let requests = [
    this.$http.ejecutar("GET", "YardEvent-list", {YardId: this.YardId}),
  ];
  if (Yard) {
    requests[1] = this.$http.ejecutar("GET", "Yard-list", {CompanyBranchId : this.CompanyBranchId, Filter: 'ACTIVO'});
  }
  Promise.all(requests)
    .then((responses) => {
      let YardEventList = responses[0].data.data;
      this.EventPerYardList = YardEventList&&YardEventList.length!=0 ? YardEventList : [];
      if (Yard) {
        let YardList = responses[1].data.data;
        this.YardOptions  = YardList&&YardList.length!=0 ? YardList : [];
      }
      this.loading = false;
    }).catch((err) => {
      this.loading = false;
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
}

//computed
function ComputedYardOptions(){
  if(this.YardOptions.length === 0){
    return [{
      value: '',
      label: this.$t('label.select'),
    }];
  }else{
    let chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.YardOptions.map(function(e){
      chart.push({
        value: e.YardId,
        label: e.YardName,
      })    
    })
    return chart;
  }
}

function fields(){
  return [
    { label: '#', key: 'Nro', _classes: 'text-center', _style: 'width:3%; text-align:center', filter: false},
    { label: this.$t('label.yard'), key: 'YardName', _classes: 'text-center', _style: 'width:15%;',},
		{ label: this.$t('label.Event'), key: 'EventName', _classes: 'text-center', _style: 'width:40%;',},
    { label: this.$t('label.user'), key: 'TransaLogin', _classes: 'text-center', _style:'width:11%' },
    { label: this.$t('label.date'), key: 'FormatedDate',_classes:'text-center', _style:'width:12%' },
    { label: this.$t('label.status'), key: 'Status', _classes: 'text-center text-center', _style:'width:12%' },
    { label: '', key: 'options', _style: 'min-width:45px;', sorter: false, filter: false },
  ];
}

function computedList() {
  let _lang = this.$i18n.locale;
  return this.EventPerYardList.map((item) => {
    return {
      ...item,
      EventName: _lang=='en' ? item.EventNameEn : item.EventNameEs,
      _classes: item.Status != 'ACTIVO' ? 'table-danger' : '',
      FormatedDate: item.TransaRegDate
        ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate)
        : 'N/A',
    };
  });
}

export default {
  name: 'events-per-yard-index',
	props: {
		Tab: {
			type: Number,
			default: 0,
		}
	},
  components: { 
    ModalEventPerYard,
  },
  data,
  mixins: [GeneralMixin],
  methods: {
    ConsultServices,
		EditEventPerYard,
  },
  computed: {
		ComputedYardOptions,
    fields,
    computedList,
    ...mapState({             
      CompanyBranchId: state => state.auth.branch.CompanyBranchId,
    }),
  },
	watch: {
		Tab: function (val) {
			if (val==3) {
				this.ConsultServices(true);
			}
		}
	}
};
</script>